<template>
  <div>
    <el-card>
      <div>
        <el-button type="primary" @click="submit" size="small">保存</el-button>
        <el-button @click="cancel" size="small">取消</el-button>
      </div>
      <el-form ref="formRef" :model="openForm" label-width="120px" class="FormCl">
        <el-form-item label="RFID：" size="small" prop="murf_no" :rules="[{ required: true, message: ' ' }]">
          <el-input ref="cardRef" v-model="openForm.murf_no" :disabled="openForm.murf_no.length === 10">
            <template slot="append">
              <el-link type="primary" class="vg_pointer" @click="emptyChange">清空</el-link>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="模具编号：" size="small" prop="samp" :rules="[{ required: true, message: ' ' }]">
          <el-input v-model="openForm.mdnc_no" :disabled="true" placeholder="请选择模具编号">
            <template slot="append">
              <el-link type="primary" class="vg_pointer" @click="openSampDialog()">选择</el-link>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="委托单号：" size="small" prop="entr" :rules="[{ required: true, message: ' ' }]">
          <el-input v-model="openForm.requ_no" disabled placeholder="请选择委托单号"></el-input>
        </el-form-item>
        <el-form-item label="开卡人姓名:" size="small" prop="stff_name" :rules="[{ required: true, message: ' ' }]">
          <el-input :disabled="true" v-model="openForm.stff_name"></el-input>
        </el-form-item>
        <el-form-item label="是否需要经历：">
          <el-checkbox v-model="openForm.procMap.设计">设计</el-checkbox>
          <el-checkbox v-model="openForm.procMap.编程">编程</el-checkbox>
          <el-checkbox v-model="openForm.procMap.钢材到位">钢材到位</el-checkbox>
          <el-checkbox v-model="openForm.procMap.模架到位">模架到位</el-checkbox>
          <el-checkbox v-model="openForm.procMap.CNC">CNC</el-checkbox>
          <el-checkbox v-model="openForm.procMap.EDM">EDM</el-checkbox>
          <el-checkbox v-model="openForm.procMap.线切割">线切割</el-checkbox>
          <el-checkbox v-model="openForm.procMap.车床">车床</el-checkbox>
          <el-checkbox v-model="openForm.procMap.省模">省模</el-checkbox>
          <el-checkbox v-model="openForm.procMap.装配">装配</el-checkbox>
          <el-checkbox v-model="openForm.procMap.表面处理">表面处理</el-checkbox>
          <el-checkbox v-model="openForm.procMap.模具完成" :disabled="true">模具完成</el-checkbox>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 模具编号 -->
    <el-dialog :visible.sync="dialogSampVisible" width="70%" title="模具信息列表(未开卡)">
      <template>
        <el-form ref="form" :model="searchSampForm" label-width="100px">
          <el-row>
            <el-col :md="8">
              <el-form-item label="模具编号：">
                <el-input v-model="searchSampForm.mdnc_no" size="small" placeholder="请输入模具编号" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="模具名称：">
                <el-input v-model="searchSampForm.mdnc_name" size="small" placeholder="请输入模具名称" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_16">
            <el-col :md="8">
              <el-button class="vg_ml_16" type="primary" size="small" @click="searchSamp">搜索</el-button>
              <el-button class="vg_mr_8" type="info" size="small" @click="searchSampO">刷新</el-button>
              <el-link type="primary" @click="gosmpl" class="vg_cursor" icon="el-icon-edit-outline">模具总控表管理 </el-link>
            </el-col>
          </el-row>
          <div class="vg_mb_8"><i class="el-icon-info vg_mr_8"></i>{{ title }}</div>
          <el-table :data="SampList" border @cell-dblclick="sampDbClick" style="width: 100%" v-loading="loadingFlag" class="vg_pointer">
            <el-table-column type="index" label="序号" align="center"></el-table-column>
            <el-table-column prop="mdnc_no" label="模具编号"></el-table-column>
            <el-table-column prop="requ_no" label="委托编号"></el-table-column>
            <el-table-column prop="mdnc_name" label="模具名称"></el-table-column>
            <el-table-column
              prop="mdnc_part_type"
              label="模具类型"
              :formatter="({ mdnc_part_type }) => getMdncPartType(mdnc_part_type)"
            ></el-table-column>
            <el-table-column prop="mdnc_part_num" label="模具数量"></el-table-column>
            <el-table-column label="创建时间" prop="create_time" :formatter="({ create_time }) => getDateNoTime(create_time)" />
          </el-table>
          <pubPagination :totalPage="SampTotal" @changePageSearch="changeSampPageSearch" ref="pubPagination"></pubPagination>
        </el-form>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getNoCatch, postNoCatch } from '@api/request';
import pubPagination from '@/components/common/pubPagination';
import selectSectionType from '@/views/component/selectSectionType';
import { getDateNoTime } from '@assets/js/dateUtils';
import { mmplAPI } from '@api/modules/mmpl';
import { muopAPI } from '@api/modules/muop';

export default {
  name: 'openCard',
  components: {
    pubPagination,
    selectSectionType
  },
  mounted() {
    this.$refs.cardRef.focus();
  },
  created() {
    this.getUserInof();
  },
  data() {
    return {
      openForm: {
        murf_no: '',
        procMap: {
          设计: true,
          编程: true,
          钢材到位: true,
          模架到位: true,
          CNC: true,
          EDM: true,
          线切割: true,
          车床: true,
          省模: true,
          装配: true,
          表面处理: true,
          检验完成: true,
          模具完成: true
        }
      },
      openFormCopy: {
        murf_no: '',
        procMap: {
          设计: true,
          编程: true,
          钢材到位: true,
          模架到位: true,
          CNC: true,
          EDM: true,
          线切割: true,
          车床: true,
          省模: true,
          装配: true,
          表面处理: true,
          检验完成: true,
          模具完成: true
        }
      },
      dialogSampVisible: false,
      SampList: [], //模具
      SampTotal: 0,
      title: ' 提示：(双击表编对应数据即可选中人员)',
      searchSampForm: {
        page_no: 1
      },
      loadingFlag: true,
      selectRefuDisabled: false,
      mouldTypeList: [
        { id: 0, label: '生产模' },
        { id: 1, label: '样品模' }
      ]
    };
  },
  methods: {
    getMdncPartType(val) {
      return this.mouldTypeList.find(x => x.id === val)?.label ?? '暂无';
    },
    getDateNoTime,
    //获取模具数据
    getSampList() {
      getNoCatch(mmplAPI.getMmplsByNoMurf, this.searchSampForm).then(({ data }) => {
        this.SampList = data.list;
        this.SampTotal = data.total;
        setTimeout(() => {
          this.loadingFlag = false;
        }, 300);
      });
    },
    //从cookie中获取信息
    getUserInof() {
      let info = this.$cookies.get('userInfo');
      this.openForm.stff_id = info.stff_id;
      this.openForm.stff_name = info.stff_name;
      this.openForm.user_id = info.user_id;
    },
    //选择 模具编号
    openSampDialog() {
      this.getSampList();
      this.dialogSampVisible = true;
    },
    openEntrDialog() {
      this.dialogEntrVisible = true;
    },
    //模具 双击获取数据
    sampDbClick(val) {
      delete val.murf_no;
      this.openForm = Object.assign(this.openForm, val);
      this.dialogSampVisible = false;
    },
    changeSampPageSearch(val) {
      this.searchSampForm.page_no = val;
      this.getSampList();
    },
    searchSamp() {
      this.loadingFlag = true;
      this.searchSampForm.page_no = 1;
      this.getSampList();
    },
    // 刷新
    searchSampO() {
      this.loadingFlag = true;
      this.searchSampForm = {
        page_no: 1
      };
      this.getSampList();
    },
    submit() {
      for (let key in this.openForm.procMap) {
        this.openForm.procMap[key] ? (this.openForm.procMap[key] = 1) : (this.openForm.procMap[key] = 0);
      }
      this.$confirm('是否开卡?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        postNoCatch(muopAPI.addMurf, this.openForm).then(({ data, msg }) => {
          this.$message.success(msg);
          this.jump(`/muop_edit?murf_id=${data.form_id}`);
          this.$refs.formRef.resetFields();
          this.openForm = this.openFormCopy;
        });
      });
    },
    cancel() {
      this.$confirm('取消开卡, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.jump('muop_list');
      });
    },
    emptyChange() {
      this.openForm.murf_no = '';
      this.$nextTick(() => {
        this.$refs.cardRef.focus();
      });
    },
    //跳入模具总控
    gosmpl() {
      this.jump(`mmpl_list?perm_id=${this.helper.retPermId('mmpl')}`);
    },
    //查询范围
    getStatusVal2(val) {
      this.searchSampForm.belo_dept_id = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 10px;
}

.FormCl {
  width: 70%;
  margin: 0 auto;
}
</style>
