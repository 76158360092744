import topic from '../topic';
export const mmplAPI = {
  getMmpls: topic.mmplTopic + '/getMmpls',
  getMmplById: topic.mmplTopic + '/getMusampleById',
  editMmpl: topic.mmplTopic + '/editMusampleById',
  deleteMmplByIds: topic.mmplTopic + '/deleteMusampleById',
  get_all_mmpls_v1: topic.mmplTopic + '/get_all_mmpls_v1',
  judgeMmplNo: topic.mmplTopic + '/judge_mmpl_no',
  getMmplMtrlByMmplId: topic.mmplTopic + '/get_mmpl_mtrl_by_mmpl_id',
  addMmplMail: topic.mmplTopic + '/add_ship_info_for_mmpls',
  getPushPostMails9003: topic.mmplTopic + '/get_push_post_mail_9003', // 获取所有post的邮件情况
  updateScanById: topic.mmplTopic + '/update_post_scan_by_id', // 重发的接口
  copyNewMmpl: topic.mmplTopic + '/copy_new_mmpl', // 同步样品
  getQutaMmplByRequId: topic.mmplTopic + '/get_quta_mmpl_by_requ_id',
  getMmplsByNoMurf: topic.mmplTopic + '/getMmplsByNoMurf' // 获取未开卡数据
};
