/**
 * @description 时间戳转换时间 - 无时分秒
 * @param {number} timestamp 无时分秒的时间戳
 * @return {string} 字符串日期(无时分秒)
 * @author HuangJun
 */
export const getDateNoTime = (timestamp, b) => {
  if (!timestamp || timestamp === 0) return '暂无';
  let now = new Date(Number(timestamp) * 1000),
    y = now.getFullYear(),
    m = now.getMonth() + 1,
    d = now.getDate();
  return `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}`;
};
/**
 * @description 时间戳转换时间
 * @param {number} timestamp 时间戳
 * @return {string} 字符串日期
 * @author HuangJun
 */
export const getDate = timestamp => {
  let now = new Date(Number(timestamp)),
    y = now.getFullYear(),
    m = now.getMonth() + 1,
    d = now.getDate();
  return `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d} ${now.toTimeString().substring(0, 8)}`;
};
export const getElapsedTime = timestamp => {
  if (!timestamp) return null;
  let divNumSecond = 1000;
  let divNumMinute = 1000 * 60;
  let divNumHour = 1000 * 3600;
  let divNumDay = 1000 * 3600 * 24;
  const day = timestamp / divNumDay - 1;
  const hour = (timestamp % divNumDay) / divNumHour;
  const minute = ((timestamp % divNumDay) % divNumHour) / divNumMinute;
  const second = (((timestamp % divNumDay) % divNumHour) % divNumMinute) / divNumSecond;
  return parseInt(day + '') + '天' + parseInt(hour + '') + '小时' + parseInt(minute + '') + '分' + parseInt(second + '') + '秒';
};
export const handleSpecialTime = string => {
  return string.substring(string.lastIndexOf('.')).replace('T', '');
};
/**
 * date类型转时间戳
 * @param {*} date
 */
export const dateToTimeStamp = date => {
  if (date instanceof Date) return date.getTime();
  return 0;
};
