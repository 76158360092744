<template>
  <el-select v-model="value2" placeholder="请选择" @change="selectStatus()" size="small" :disabled="disabled">
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
  </el-select>
</template>

<script>
import { optnAPI } from '@api/modules/optn';
import { get, post } from '@api/request';
export default {
  name: 'selectStatus',
  props: {
    //是否允许选择 默认可以
    // disabled:{
    //   type:Boolean,
    //   default:false
    // }
  },
  created() {
    this.getDepartment();
    this.getOptnByPermId();
  },
  data() {
    return {
      value2: '全部',
      disabled: true,
      options: [
        {
          value: null,
          label: '全部'
        }
      ]
    };
  },
  methods: {
    getDepartment() {
      let userInfo = this.$cookies.get('userInfo');
      if (userInfo.dept_id === 2 || userInfo.dept_id === 17) {
        this.value2 = userInfo.dept_id + '';
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    selectStatus() {
      this.$emit('selectStatusRow', this.value2);
    },
    getOptnByPermId() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 }).then(res => {
        if (res.data.code === 0) {
          res.data.data.form.optn_cntt_list.forEach(item => {
            this.options.push({
              value: item.param2,
              label: item.param1
            });
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
